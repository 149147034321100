<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if(validationFailed){
                    <div class="ontario-alert ontario-alert--error">
                        <div class="ontario-alert__header">
                            <div class="ontario-alert__header-icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </div>
                            <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                        </div>
                        <div class="ontario-alert__body">
                            <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                            <ul>
                                @if(businessNameTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToElement('businessnamelabel')">{{'Healthandsafetypledge.BusinessNameMaxLength' | translate }}</a></li>
                                }
                                @if(businessNameInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('businessnamelabel')">{{ 'Healthandsafetypledge.BusinessNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                                @if(nameTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToElement('ownernamelabel')">{{ 'Healthandsafetypledge.NameMaxLength' | translate }}</a></li>
                                }
                                @if(nameInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('ownernamelabel')">{{ 'Healthandsafetypledge.OwnerNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                                @if(roleTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToElement('ownerrolelabel')">{{ 'Healthandsafetypledge.RoleMaxLength' | translate }}</a></li>
                                }
                                @if(roleInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('ownerrolelabel')">{{ 'Healthandsafetypledge.OwnerRoleHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                                @if(invalidError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('businessnamelabel')">{{'HardcodedGlobals.OneOfInvalidError' | translate}}</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
          <h1 class="title">
            {{'Healthandsafetypledge.Title' | translate}}
          </h1>
          <div class="ontario-lead-statement" innerHtml="{{ 'Healthandsafetypledge.Summary' | translate }}"></div>
          <br>          
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
                <legend></legend>
                <label class="ontario-label" for="businessname" id="businessnamelabel">
                    {{'Healthandsafetypledge.BusinessName' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                </label>
                @if(businessNameTooLong){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'Healthandsafetypledge.BusinessNameMaxLength' | translate }}
                        </span>
                    </div>
                }      
                @if(businessNameInvalid){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{ 'Healthandsafetypledge.BusinessNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}
                        </span>
                    </div>
                }         
                <input class="ontario-input" type="text" id="businessname" [(ngModel)]="businessName" [class.ontario-input__error]="businessNameTooLong || businessNameInvalid || invalidError">
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
                <legend></legend>
                <label class="ontario-label" for="ownername" id="ownernamelabel">
                    {{'Healthandsafetypledge.OwnerName' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                </label>
                @if(nameTooLong){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'Healthandsafetypledge.NameMaxLength' | translate }}
                        </span>
                    </div>
                }
                @if(nameInvalid){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{ 'Healthandsafetypledge.OwnerNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}
                        </span>
                    </div>
                }
                <input class="ontario-input" type="text" id="ownername" [(ngModel)]="ownerName" [class.ontario-input__error]="nameTooLong || nameInvalid || invalidError">
        </fieldset>
        </div>
        
    </div>

    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
                <legend></legend>
                <label class="ontario-label" for="ownerrole" id="ownerrolelabel">
                    {{'Healthandsafetypledge.OwnerRole' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                </label>
                @if(roleTooLong){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'Healthandsafetypledge.RoleMaxLength' | translate }}
                        </span>
                    </div>
                }
                @if(roleInvalid){
                    <div class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{ 'Healthandsafetypledge.OwnerRoleHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}
                        </span>
                    </div>
                }
                <input class="ontario-input" type="text" id="ownerrole" [(ngModel)]="ownerRole" [class.ontario-input__error]="roleTooLong || roleInvalid || invalidError">
        </fieldset>
        </div>
        
    </div>
    <div class="ontario-row">
        <div class="ontario-callout"> 
            <h2 class="ontario-callout__title ontario-h2">{{ 'Healthandsafetypledge.CalloutHeading' | translate }}</h2>
            @if(EnPage){
                <div>
                    <p>
                        Your employer, {{businessName.length ? businessName : '[insert name of business]'}}, and its senior management are vitally interested in the health and safety of its workers. Protection of workers from injury or occupational disease is a major continuing objective.<br><br>
                        <b>{{businessName.length ? businessName : '[Insert name of business]'}}</b> will make every effort to provide a safe, healthy work environment. The employer, supervisors and workers must be dedicated to the continuing objective of reducing the risk of injury.<br><br>
                        <b>{{businessName.length ? businessName : '[Insert name of business]'}}</b>, as employer has the most responsibility for worker health and safety. As <b>{{ownerRole.length ? ownerRole : '[owner/operator, chairperson, chief executive officer, etc.]'}}</b> of <b>{{businessName.length ? businessName : '[insert name of business]'}}</b>, I give you my personal commitment that <b>{{businessName.length ? businessName : '[insert name of business]'}}</b> will comply with its duties under the Act, such as taking every reasonable precaution for the protection of workers in the workplace.<br><br>
                        Supervisors also share responsibility for the health and safety of workers under their supervision. Supervisors are subject to various duties in the workplace, including the duty to ensure that workers work in compliance with safe work practices and procedures required by the OHSA and the regulations made under it.<br><br>
                        Every worker must protect their own health and safety by working in compliance with the law and with safe work practices and procedures established by the employer. Workers will receive information, training and competent supervision in their specific work tasks to protect their health and safety.<br><br>
                        It is in the best interest of all parties to consider health and safety in every activity. Commitment to health and safety must form an integral part of this organization, from the president to the workers.
                    </p>
                </div>   
            }       
            @if(FrPage){
                <div>
                    <p>
                        L'employeur et la haute direction de {{businessName.length ? businessName : '[insérer le nom de l’entreprise]'}} s’intéressent au plus haut point à la santé et à la sécurité de leurs travailleurs. La protection des travailleurs contre les blessures au travail et les maladies professionnelles constitue un objectif permanent.<br><br>
                        <b>{{businessName.length ? businessName : '[Insérer le nom de l’entreprise]'}}</b> fera tous les efforts possibles pour assurer la santé et la sécurité dans le lieu de travail. L’employeur et tous les superviseurs et travailleurs doivent s’appliquer à réaliser cet objectif permanent en vue de réduire les risques d’accident.<br><br>
                        C'est à <b>{{businessName.length ? businessName : '[Insérer le nom de l’entreprise]'}}</b>, à titre d’employeur, qu’il revient d’assurer la santé et la sécurité des travailleurs. En tant de <b>{{ownerRole.length ? ownerRole : '[propriétaire/exploitant]'}}</b> de <b>{{businessName.length ? businessName : '[insérer le nom de l’entreprise]'}}</b>, je m'engage de <b>{{businessName.length ? businessName : '[insérer le nom de l’entreprise]'}}</b>, je m’engage personnellement à respecter mes devoirs aux termes de la Loi, notamment à prendre toutes les précautions raisonnables pour la protection des travailleurs dans le lieu de travail.<br><br>
                        Les superviseurs sont également responsables de la santé et de la sécurité des travailleurs qu’ils dirigent. Les superviseurs ont divers devoirs à observer dans le lieu de travail, notamment le devoir de veiller à ce que les travailleurs se conforment aux pratiques et aux procédés établis en matière de sécurité au travail par la Loi sur la santé et la sécurité au travail et ses règlements d’application<br><br>
                        Chaque travailleur doit protéger sa propre santé et sa propre sécurité en respectant la Loi et en suivant les pratiques et les procédés établis par l’employeur pour assurer la sécurité au travail. Afin de protéger leur santé et leur sécurité, les travailleurs recevront les renseignements, la formation et la surveillance nécessaires à leurs tâches particulières.<br><br>
                        Toutes les parties en cause ont intérêt à tenir compte de la santé et de la sécurité dans leurs activités. L’engagement envers la santé et la sécurité fait partie intégrante de notre entreprise, depuis le président jusqu’aux travailleurs.
                    </p>
                </div>
            }                
            <br />
            <p><br />{{ 'Healthandsafetypledge.Signed' | translate }}: <br /></p>
            <br />
            <table role="presentation">
                <tr style="display: table-header-group;">
                    <td style="padding: 0%;"><hr style="margin-right: 10px; border-color: #1A1A1A;"></td>
                    <td style="padding: 0%;"><hr style="border-color: #1A1A1A;"></td>
                </tr>
                <tr>
                    <th>{{ownerName.length ? ownerName : EnPage ? '[name]' : '[nom]'}}</th>
                    <th>{{ 'Healthandsafetypledge.DateStrong' | translate }}</th>
                </tr>
            </table>
        </div>
        <button (click)="download()" class="ontario-button ontario-button--primary">
            {{ 'Buttons.DownloadPDF' | translate}}
        </button>
        <button (click)="downloadWord()" class="ontario-button ontario-button--primary">
            {{ 'ViolenceAndHarassment.DownloadWord' | translate}}
        </button>
        <button (click)="saveAndContinue()" class="ontario-button ontario-button--secondary">
            {{ 'Buttons.Save' | translate}}
        </button>
    </div>
  </div>