
<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if(validationFailed||subOptionValidationFailed){
                    <div class="ontario-alert ontario-alert--error" id="errorHeading" #errorHeading tabindex="0">
                        <div class="ontario-alert__header">
                            <div class="ontario-alert__header-icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </div>
                            <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                        </div>
                        <div class="ontario-alert__body">
                            <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                            <ul>
                                @if(selectedOptionsInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('checkbox-option-1')">{{'HardcodedGlobals.AtLeastOne' | translate}}</a></li>
                                }   
                                @if(subOptionValidationFailed){
                                    <li><a href="javascript:{}" (click)="scrollToElement('checkbox-option-2')">{{'HardcodedGlobals.AtLeastOne' | translate}}</a></li>
                                }
                                @if(otherSubOptionEmpty){
                                    <li><a href="javascript:{}" (click)="scrollToOtherInput('otherSubOption')">{{'NewPlanStep3.EntryNotEmpty' | translate}}</a></li>
                                }
                                @if(otherSubOptionTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToOtherInput('otherSubOption')">{{'NewPlanStep3.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}</a></li>
                                }
                                @if(otherInputEmpty){
                                    <li><a href="javascript:{}" (click)="scrollToOtherInput('otherOption')">{{'NewPlanStep3.EntryNotEmpty' | translate}}</a></li>
                                }
                                @if(otherInputTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToOtherInput('otherOption')">{{'NewPlanStep3.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Title3' | translate}}</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>         
            <h1 class="title">
                {{ 'NewPlanStep3.Title' | translate}}
            </h1>
            <div class="ontario-lead-statement ontario-padding-bottom-24-!">{{ 'NewPlanStep3.Introduction' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span></div>
            <br>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="select-all-that-apply">
                {{'NewPlanStep3.SelectAll' | translate}}
            </legend>
            @if(selectedOptionsInvalid){
                <div class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.AtLeastOne' | translate}}
                    </span>
                </div>
            }
            <div class="ontario-checkboxes ontario-checkboxes__error">
                <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-1" name="options" type="checkbox" value="option-1" (change)="onCheckboxChange('option-1')"  [checked]="selectedOptions.includes('option-1')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-1">
                        {{'NewPlanStep3.Option1' | translate}}
                    </label>
                </div>
                <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-2" name="options" type="checkbox" value="option-2" (change)="onCheckboxChange('option-2')"  [checked]="selectedOptions.includes('option-2')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-2">
                        {{'NewPlanStep3.Option2' | translate}}
                    </label>
                    @if(selectedOptions.includes('option-2')){
                        <div class="ontario-form-group">
                            <p> {{'NewPlanStep3.InfoIsShared' | translate}}</p><br>
                            @if(subOptionValidationFailed){
                                <div class="ontario-error-messaging" id="field-error">
                                    <span class="ontario-error-messaging__icon">
                                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                            <use href="#ontario-icon-alert-error"></use>
                                        </svg>
                                    </span>
                                    <span class="ontario-error-messaging__content">
                                        {{'HardcodedGlobals.AtLeastOne' | translate}}
                                    </span>
                                    <br>
                                </div>
                            }
                            <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-suboption-1" name="options" type="checkbox" value="suboption-1" (change)="onCheckSubOptionChange('suboption-1')" [checked]="selectedSubOptions.includes('suboption-1')">
                                <label class="ontario-checkboxes__label" for="checkbox-suboption-1">
                                    {{'NewPlanStep3.Suboption1' | translate}}
                                </label>
                            </div>
                            <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-suboption-2" name="options" type="checkbox" value="suboption-2" (change)="onCheckSubOptionChange('suboption-2')" [checked]="selectedSubOptions.includes('suboption-2')">
                                <label class="ontario-checkboxes__label" for="checkbox-suboption-2">
                                    {{'NewPlanStep3.Suboption2' | translate}}
                                </label>
                            </div>
                            <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-suboption-3" name="options" type="checkbox" value="suboption-3" (change)="onCheckSubOptionChange('suboption-3')" [checked]="selectedSubOptions.includes('suboption-3')">
                                <label class="ontario-checkboxes__label" for="checkbox-suboption-3">
                                    {{'NewPlanStep3.Suboption3' | translate}}
                                </label>
                            </div>
                            <!-- <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-suboption-4" name="options" type="checkbox" value="suboption-4" (change)="onCheckSubOptionChange('suboption-4')" [checked]="selectedSubOptions.includes('suboption-4')">
                                <label class="ontario-checkboxes__label" for="checkbox-suboption-4">
                                    {{'NewPlanStep3.Suboption4' | translate}}
                                </label>
                            </div> -->
                            <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-suboption-5" name="options" type="checkbox" value="other" (change)="onCheckSubOptionChange('other')" [checked]="selectedSubOptions.includes('other')">
                                <label class="ontario-checkboxes__label" for="checkbox-suboption-5">
                                    {{'NewPlanStep3.Suboption5' | translate}}
                                </label>
                                @if(selectedSubOptions.includes('other')){
                                    <div>
                                        <br />
                                        @if(otherSubOptionEmpty){
                                            <div class="ontario-error-messaging" id="field-error">
                                                <span class="ontario-error-messaging__icon">
                                                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                                        <use href="#ontario-icon-alert-error"></use>
                                                    </svg>
                                                </span>
                                                <span class="ontario-error-messaging__content">
                                                    {{'NewPlanStep3.EntryNotEmpty' | translate}}
                                                </span>
                                            </div>
                                        }
                                        @if(otherSubOptionTooLong){
                                            <div class="ontario-error-messaging" id="field-error">
                                                <span class="ontario-error-messaging__icon">
                                                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                                        <use href="#ontario-icon-alert-error"></use>
                                                    </svg>
                                                </span>
                                                <span class="ontario-error-messaging__content">
                                                    {{'NewPlanStep3.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}
                                                </span>
                                            </div>
                                        }
                                        <div class="dynamic-input-container">
                                            <input class="ontario-input" type="text" [(ngModel)]="otherSubOption" id="otherSubOption" aria-label="Enter a value" [class.ontario-input__error]="otherSubOptionEmpty||otherSubOptionTooLong"/>
                                        </div>
                                    </div>
                                }
                            </div>                        
                        </div>
                    }
                </div>
                <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-3" name="options" type="checkbox" value="option-3" (change)="onCheckboxChange('option-3')"  [checked]="selectedOptions.includes('option-3')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-3">
                        {{'NewPlanStep3.Option3' | translate}}
                    </label>
                </div>
                <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-4" name="options" type="checkbox" value="option-4" (change)="onCheckboxChange('option-4')"  [checked]="selectedOptions.includes('option-4')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-4">
                        {{'NewPlanStep3.Option4' | translate}}
                    </label>
                </div>
                <!-- <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-5" name="options" type="checkbox" value="option-5" (change)="onCheckboxChange('option-5')"  [checked]="selectedOptions.includes('option-5')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-5">
                        {{'NewPlanStep3.Option5' | translate}}
                    </label>
                </div> -->
                <div class="ontario-checkboxes__item">
                    <input class="ontario-checkboxes__input" id="checkbox-option-6" name="options" type="checkbox" value="option-6" (change)="onCheckboxChange('other')"  [checked]="selectedOptions.includes('other')">
                    <label class="ontario-checkboxes__label" for="checkbox-option-6">
                        {{'NewPlanStep3.Option6' | translate}}
                    </label>
                </div>
            </div>
        </fieldset>
        @if(selectedOptions.includes('other')){
            <div id="other">
                <ng-template #maxFieldMessage>
                    <p>{{ 'General.MaxFieldMessage' | translate}}</p>
                </ng-template>
                @if(otherInputEmpty){
                    <div class="ontario-error-messaging" id="field-error">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'NewPlanStep3.EntryNotEmpty' | translate}}
                        </span>
                    </div>
                }
                @if(otherInputTooLong){
                    <div class="ontario-error-messaging" id="field-error">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'NewPlanStep3.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}
                        </span>
                    </div>
                }
                <div class="dynamic-input-container">
                    <input class="ontario-input" type="text" [(ngModel)]="otherOption" id="otherOption" aria-label="Enter a value" [class.ontario-input__error]="otherInputEmpty||otherInputTooLong"/>
                </div>
            </div>
        }
        <button class="ontario-button ontario-button--secondary" (click)="back()">
            {{ 'Buttons.Previous' | translate}}
        </button>
        <button (click)="saveAndContinue()"  class="ontario-button ontario-button--primary">
            {{ 'Buttons.SaveAndContinue' | translate}}
        </button>
        </div>
    </div>
  </div>