import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectedBusinessModuleControlMeasureModel } from 'src/app/models/controlMeasure.model';
import { ControlMeasureModel , BusinessModuleControlMeasureModel} from 'src/app/models/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ControlMeasureService {

  constructor(private httpClient: HttpClient) { }

  getControlMeasure(moduleId: string): Observable<ControlMeasureModel[]> {
    return this.httpClient.get<ControlMeasureModel[]>(`${environment.apiBaseUrl}ControlMeasure`, { params: {moduleId: moduleId} });
  }

  getSelectedControlMeasures(businessModuleId: string): Observable<BusinessModuleControlMeasureModel[]> {
    return this.httpClient.get<BusinessModuleControlMeasureModel[]>(`${environment.apiBaseUrl}ControlMeasure/getSelectedControlMeasures`, { params: {businessModuleId: businessModuleId} });
  }

  changeSelectedControlMeasures(businessModuleId: string, controlMeasureIds: SelectedBusinessModuleControlMeasureModel[]): Observable<number> {
    return this.httpClient.post<number>(`${environment.apiBaseUrl}ControlMeasure/changeSelectedControlMeasures`, controlMeasureIds,{ params: { businessModuleId: businessModuleId } });
  }
}
