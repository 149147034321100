<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h1 class="title" >
            <p innerHtml="{{'WSIB.Title' | translate}}"></p>
        </h1>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'WSIB.Content1' | translate}}"></p>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <div class="link"> <p innerHtml="{{'WSIB.PosterLink' | translate}}"></p> <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" ><use href="#ontario-icon-new-window"></use></svg></div>
            <br>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            @if (EnPage) {
                <div class="img-container">
                    <img src="/assets/images/1234_en.jpg" alt='In case of injury poster ("1234") preview' class="scaled-image">
                </div>    
            } @else if (FrPage) {
                <div class="img-container">
                    <img src="/assets/images/1234_fr.jpg" alt='Aperçu de l’affiche en cas de blessure (« 1234 »)' class="scaled-image">
                </div>    
            }
    </div>
</div>
