<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        @if(showSuccessAlert){
            <div id="sucessPageAlert" class="ontario-alert ontario-alert--success">
                <div class="ontario-alert__header">
                    <div class="ontario-alert__header-icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                            viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-success"></use>
                        </svg>
                    </div>
                    @if(showAddedUser){
                        <h2 class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.UserHasBeenAdded' | translate}}</h2>
                    }
                    @if(showRemoveUser){
                        <h2 class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.UserHasBeenRemoved' | translate}}</h2>
                    }   
                    @if(showEmailPreferenceUpdated){
                        <h2 class="ontario-alert__header-title ontario-h4">{{'WorkspaceManagement.EmailPreferenceUpdated' | translate}}</h2>
                    }   
                </div>
                <div class="ontario-alert__body">
                    @if(showAddedUser){
                        <p>{{'WorkspaceManagement.UserHasBeenAddedSummary' | translate}}</p>
                    }
                    @if(showRemoveUser){
                        <p>{{'WorkspaceManagement.UserHasBeenRemovedSummary' | translate}}</p>
                    }
                    @if(showEmailPreferenceUpdated){
                        <p>{{'WorkspaceManagement.EmailPreferenceUpdatedSummary' | translate}}</p>
                    }
                </div>
            </div>
        }
        <div class="ontario-columns top-banner">
            <h1 class="title">
            {{'WorkspaceManagement.AccountSettings' | translate}}
            </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <p class="ontario-lead-statement">{{'WorkspaceManagement.Summary' | translate}}</p><br />
            <div class="list-header">
                <h2>{{'WorkspaceManagement.YourTeam' | translate}}</h2>  
                <button class="ontario-button ontario-button--primary add-button" (click)="openAddDialog()">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-add"></use>
                    </svg>{{ 'Buttons.AddTeamMember' | translate}}
                </button>
            </div>
        </div> 
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-table-container ontario-small-12 ontario-medium-12 ontario-large-12">
                <div class="ontario-table-div">
                    <table class="user-table" tabindex="-1">
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{'WorkspaceManagement.Email' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(user of users; track user; let i = $index) {
                                @defer {
                                    <tr>
                                        <td class="user-table-row" scope="row">
                                            <span>
                                                <b>{{user.userEmail}} </b>
                                                @if(!user.hasLoggedIn){
                                                    <span class="ontario-badge ontario-badge--default-light">{{'WorkspaceManagement.Invited' | translate}}</span>
                                                }
                                            </span>
                                            <div class="buttons">
                                                @if(user.userEmail == currentUser.Email){
                                                    <button class="ontario-button ontario-button--secondary" (click)="openEmailPreferenceDialog()">
                                                        {{'WorkspaceManagement.EmailPreferences' | translate}}
                                                    </button>
                                                }
                                                @if(shouldShowRemoveButton() || user.userEmail != currentUser.Email){
                                                    <button class="ontario-button ontario-button--tertiary" (click)="openRemoveDialog(i)">
                                                        {{ 'WorkspaceManagement.Remove' | translate}}
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }
                            }
                        </tbody>
                    </table>
                </div>
            </div>          
        </div>
    </div>
</div>
       