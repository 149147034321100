<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if(validationFailed){
                    <div class="ontario-alert ontario-alert--error"  id="errorHeading" #errorHeading tabindex="0">
                        <div class="ontario-alert__header">
                            <div class="ontario-alert__header-icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </div>
                            <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                        </div>
                        <div class="ontario-alert__body">
                            <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                            <ul>
                                @if(Q1RadioInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('radio-button-option-1')">{{'HardcodedGlobals.AtLeastOne' | translate}}</a></li>
                                }
                                @if(otherIntervalEmpty){
                                    <li><a href="javascript:{}" (click)="scrollToElement('otherIntervalId')"> {{'NewPlanStep4.EntryNotEmpty' | translate}}</a></li>
                                }
                                @if(otherIntervalTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToElement('otherIntervalId')">{{'NewPlanStep4.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}</a></li>
                                }
                                @if(selectedOptionsInvalid){
                                    <li><a href="javascript:{}" (click)="scrollToElement('checkbox-option-2')">{{'HardcodedGlobals.AtLeastOne' | translate}}</a></li>
                                }
                                @if(otherInputEmpty){
                                    <li><a href="javascript:{}" (click)="scrollToElement('otherOptionId')"> {{'NewPlanStep4.EntryNotEmpty' | translate}}</a></li>
                                }
                                @if(otherInputTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToElement('otherOptionId')">{{'NewPlanStep4.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Title4' | translate}}</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>         
            <h1 class="title">
                {{'NewPlanStep4.Title' | translate}}
            </h1>
            <div class="ontario-lead-statement ontario-padding-bottom-24-!" innerHtml="{{'NewPlanStep4.Summary' | translate}}"></div>
            <!-- <br />
            <p class="item-top-text question" innerHtml="{{'NewPlanStep4.Question' | translate}}"></p>
            <br /> -->
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend class="ontario-fieldset__legend" id="businessNameLabel">
                    {{'NewPlanStep4.Question1' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </legend>
                @if(validationFailed && Q1RadioInvalid){
                    <div class="ontario-error-messaging" id="field-error">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'HardcodedGlobals.AtLeastOne' | translate}}
                        </span>
                    </div>
                }
                <div class="ontario-radios">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-1" name="radio-buttons" type="radio" value="EveryMonth" [(ngModel)]="Q1RadioValue">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-1">{{'NewPlanStep4.EveryMonth' | translate}}</label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2" name="radio-buttons" type="radio" value="EveryThreeMonths" [(ngModel)]="Q1RadioValue">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2">{{'NewPlanStep4.EveryThreeMonths' | translate}}</label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-3" name="radio-buttons" type="radio" value="TwiceAYear" [(ngModel)]="Q1RadioValue">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-3">{{'NewPlanStep4.TwiceAYear' | translate}}</label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-4" name="radio-buttons" type="radio" value="OnceAYear" [(ngModel)]="Q1RadioValue">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-4">{{'NewPlanStep4.OnceAYear' | translate}}</label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-5" name="radio-buttons" type="radio" value="other" [(ngModel)]="Q1RadioValue">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-5">{{'NewPlanStep4.Other' | translate}}</label>
                    </div>
                </div>
                @if(Q1RadioValue === 'other'){
                    <div id="otherInterval">
                        @if(otherIntervalEmpty){
                            <div class="ontario-error-messaging" id="field-error">
                                <span class="ontario-error-messaging__icon">
                                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-alert-error"></use>
                                    </svg>
                                </span>
                                <span class="ontario-error-messaging__content">
                                    {{'NewPlanStep4.EntryNotEmpty' | translate}}
                                </span>
                            </div>
                        }
                        @if(otherIntervalTooLong){
                            <div class="ontario-error-messaging" id="field-error">
                                <span class="ontario-error-messaging__icon">
                                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-alert-error"></use>
                                    </svg>
                                </span>
                                <span class="ontario-error-messaging__content">
                                    {{'NewPlanStep4.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}
                                </span>
                            </div>
                        }
                        <div class="dynamic-input-container">
                            <input class="ontario-input" type="text" [(ngModel)]="otherInterval" id="otherIntervalId" aria-label="Enter a value" [class.ontario-input__error]="otherIntervalValidationFailed"/>
                        </div>
                    </div>
                }
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend class="ontario-fieldset__legend" id="businessNameLabel">
                    {{'NewPlanStep4.Question2' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                    <p>{{'HardcodedGlobals.SelectAll' | translate}}</p>
                </legend>
                <div>
                    <div id="checkboxGroup">
                        @if(selectedOptionsInvalid){
                            <div class="ontario-error-messaging" id="checkbox-error">
                                <span class="ontario-error-messaging__icon">
                                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-alert-error"></use>
                                    </svg>
                                </span>
                                <span class="ontario-error-messaging__content">
                                    {{'HardcodedGlobals.AtLeastOne' | translate}}
                                </span>
                            </div>
                        }
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-2" name="options" type="checkbox"
                                value="option-2" (change)="onCheckboxChange('option-2')" [checked]="selectedOptions.includes('option-2')">
                            <label class="ontario-checkboxes__label" for="checkbox-option-2">
                                {{'NewPlanStep4.Option2' | translate}}
                            </label>
                        </div>
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-3" name="options" type="checkbox"
                                value="option-3" (change)="onCheckboxChange('option-3')" [checked]="selectedOptions.includes('option-3')">
                            <label class="ontario-checkboxes__label" for="checkbox-option-3">
                                {{'NewPlanStep4.Option3' | translate}}
                            </label>
                        </div>
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-4" name="options" type="checkbox"
                                value="option-4" (change)="onCheckboxChange('option-4')" [checked]="selectedOptions.includes('option-4')">
                            <label class="ontario-checkboxes__label" for="checkbox-option-4">
                                {{'NewPlanStep4.Option4' | translate}}
                            </label>
                        </div>
                        <!-- <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-5" name="options" type="checkbox"
                                value="option-5" (change)="onCheckboxChange('option-5')" [checked]="selectedOptions.includes('option-5')">
                            <label class="ontario-checkboxes__label" for="checkbox-option-5">
                                {{'NewPlanStep4.Option5' | translate}}
                            </label>
                        </div> -->
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-6" name="options" type="checkbox" value="option-6" (change)="onCheckboxChange('other')" [checked]="selectedOptions.includes('other')">
                            <label class="ontario-checkboxes__label" id="Q6label" for="checkbox-option-6" id="othersTextlabel">{{'NewPlanStep4.Option6' | translate}}</label>
                            <div class="ontario-row">
                                <div class="ontario-form-group">
                                </div>
                            </div>
                        </div>
                    </div>
                    @if(selectedOptions.includes('other')){
                        <div id="other">
                            @if(otherInputEmpty){
                                <div class="ontario-error-messaging" id="field-error">
                                    <span class="ontario-error-messaging__icon">
                                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                            <use href="#ontario-icon-alert-error"></use>
                                        </svg>
                                    </span>
                                    <span class="ontario-error-messaging__content">
                                        {{'NewPlanStep4.EntryNotEmpty' | translate}}
                                    </span>
                                </div>
                            }
                            @if(otherInputTooLong){
                                <div class="ontario-error-messaging" id="field-error">
                                    <span class="ontario-error-messaging__icon">
                                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                            <use href="#ontario-icon-alert-error"></use>
                                        </svg>
                                    </span>
                                    <span class="ontario-error-messaging__content">
                                        {{'NewPlanStep4.TheEntry' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 150} }}
                                    </span>
                                </div>
                            }
                            <div class="dynamic-input-container">
                                <input class="ontario-input" type="text" [(ngModel)]="otherOption" id="otherOptionId" aria-label="Enter a value" [class.ontario-input__error]="otherInputValidationFailed"/>
                            </div>
                        </div>  
                    }  
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <button class="ontario-button ontario-button--secondary" (click)="back()">
                {{ 'Buttons.Previous' | translate}}
            </button>
            <button (click)="saveAndContinue()"  class="ontario-button ontario-button--primary">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>
    </div>
</div>