import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SafetyPlanService } from 'src/app/services/safetyplan/safetyplan.service';
import { BusinessService } from 'src/app/services/business/business.service';

@Component({
  selector: 'app-newplanstep3',
  standalone: true,
  imports: [CommonModule, TranslateModule,FormsModule],
  templateUrl: './newplanstep3.component.html',
  styleUrls: ['./newplanstep3.component.scss']
})
export class Newplanstep3Component {

  constructor(
    public authService: AuthService, 
    public translate: TranslateService, 
    public router: Router, 
    private safetyPlanService: SafetyPlanService,
    public activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private businessService: BusinessService
  ) { }
  
  newPlanId!: string;

  validationFailed: boolean = false;
  selectedOptionsInvalid: boolean = false;
  subOptionValidationFailed: boolean = false;
  otherInputEmpty: boolean = false;
  otherInputTooLong: boolean = false;
  otherSubOptionEmpty: boolean = false;
  otherSubOptionTooLong: boolean = false;

  selectedOptions: string[] = [];
  selectedSubOptions: string[] = [];
  otherOption: string = '';
  otherSubOption: string = '';
  @ViewChild('errorHeading', { read: ElementRef }) errorHeading! : ElementRef;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.newPlanId = params['id'];
      if (this.newPlanId) {
        this.loadPlanDetails(this.newPlanId);
      }
    });
  }

  loadPlanDetails(planId: string) {
    this.safetyPlanService.getSafetyPlan(planId).subscribe((details: any) => {
      this.businessService.getBusiness().subscribe(b => {
        if (details && b.id == details.businessId) {
          this.selectedOptions = details.communicationTrainingOptions || [];
          this.selectedSubOptions = details.communicationTrainingSubOptions || [];
          this.otherOption = details.communicationTrainingOtherOption || '';
          this.otherSubOption = details.communicationTrainingOtherSubOption || '';
        }
        else 
        this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
      });
    });
  }

  resetFields() {
    this.validationFailed = false;
    this.otherInputEmpty = false;
    this.otherInputTooLong = false;
    this.selectedOptionsInvalid = false;
    this.subOptionValidationFailed = false;
    this.otherSubOptionEmpty = false;
    this.otherSubOptionTooLong = false;
  }

  scrollToElement(el: string) {
    const element = document.getElementById(el)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      element.focus();
    }
  }
  scrollToOtherInput(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }

  validateCheckboxes() {
    this.selectedOptionsInvalid = this.selectedOptions.length === 0;
  }

  subOptionCheck(){
    this.subOptionValidationFailed = this.selectedSubOptions.length === 0 && this.selectedOptions.includes('option-2');
  }

  back() {
    this.router.navigate(['../../newplanstep2', this.newPlanId], { relativeTo: this.activatedRoute });
  }

  saveAndContinue() {
    this.resetFields();
    this.validateCheckboxes();
    this.subOptionCheck();

    this.validationFailed = this.selectedOptionsInvalid || this.subOptionValidationFailed;

    if(this.selectedOptions.includes('other') && this.otherOption.trim() === '') {
      this.validationFailed = true;
      this.otherInputEmpty = true;
    }

    if(this.selectedOptions.includes('other') && this.otherOption.trim().length > 150) {
      this.validationFailed = true;
      this.otherInputTooLong = true;
    }

    if(this.selectedSubOptions.includes('other') && this.otherSubOption.trim() === '') {
      this.validationFailed = true;
      this.otherSubOptionEmpty = true;
    }

    if(this.selectedSubOptions.includes('other') && this.otherSubOption.trim().length > 150) {
      this.validationFailed = true;
      this.otherSubOptionTooLong = true;
    }
    
    if (this.validationFailed) {  
      this.changeDetector.detectChanges();
      this.errorHeading?.nativeElement?.focus();
      this.errorHeading?.nativeElement?.scrollIntoView();      
    } else {
      this.safetyPlanService.updateStep3Plan(this.newPlanId, this.selectedOptions, this.selectedSubOptions, this.otherOption, this.otherSubOption).subscribe(() => {
        this.router.navigate(['../../newplanstep4', this.newPlanId], { relativeTo: this.activatedRoute });
      });
    }    
  }

  onCheckboxChange(optionValue: string) {
    if (this.selectedOptions.includes(optionValue)) {
      this.selectedOptions = this.selectedOptions.filter(val => val !== optionValue);
      if(optionValue === 'other') {
        this.otherOption = '';
      }
      if(optionValue === 'option-2'){
        this.selectedSubOptions = [];
      }
    } else {
      this.selectedOptions.push(optionValue);
    }
  }
  
  onCheckSubOptionChange(subOptionValue: string) {
    if (this.selectedSubOptions.includes(subOptionValue)) {
      this.selectedSubOptions = this.selectedSubOptions.filter(val => val !== subOptionValue);
    } else {
      this.selectedSubOptions.push(subOptionValue);
    }
  }
}