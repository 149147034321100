<div class='documentation-only--application'>
    <div class='ontario-header__container'>
        <header class='ontario-application-header ontario-header' id='ontario-header'>
            <div class='ontario-row'>
                <div class='ontario-columns ontario-small-6 ontario-application-header__logo'>
                    <a href="{{'General.OntarioLink' | translate}}">
                        <img src='assets/ontario-design-system/logos/ontario-logo--desktop.svg'
                            alt="{{'General.OntarioLogoAlt' | translate}}" role='img' />
                    </a>
                </div>
                @if (this.translate.currentLang == 'en') {
                    <div class="ontario-columns ontario-small-6 ontario-application-header__lang-toggle" id="french-button">
                    <a tabindex="0" lang="fr"
                        class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline"
                        (click)="changeLanguage('fr')" (keyup.enter)="changeLanguage('fr')">
                        <span>Français</span>
                    </a>
                </div>
                }
                @if (this.translate.currentLang == 'fr') {
                <div class="ontario-columns ontario-small-6 ontario-application-header__lang-toggle" id="english-button">
                    <a tabindex="0"
                        class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline"
                        (click)="changeLanguage('en')" (keyup.enter)="changeLanguage('en')">
                        <span>English</span>
                    </a>
                </div>
                }
            </div>
        </header>
        <div class='ontario-application-subheader-menu__container my-app' [ngClass]="{'fr': this.translate.currentLang == 'fr'}" [ngStyle]="{'display': (subheaderService.showSubheader$ | async) ? 'block' : 'none'}">
            <section class='ontario-application-subheader'>
                <div class='ontario-row'>
                    <nav class='ontario-columns ontario-small-12 ontario-application-subheader__container'>                        
                            <h1 class='ontario-application-subheader__heading'>
                                <a (click)="navigateHome()" tabindex="0">{{title}}</a>
                            </h1>
                        @if (!authService.loggedInStatus.value) {
                            @defer{
                                <app-login id="login"></app-login>                       
                            }
                        }                        
                        <div id="divLinksMenu" class='ontario-application-subheader__menu-container'>
                            <ul class='ontario-application-subheader__menu custom-show-for-large'>
                                <li>
                                    @defer{
                                        <app-feedback></app-feedback>
                                    }
                                </li>
                                <li><a [routerLink]="[translate.currentLang === 'en' ? '/injuryreporting' : 'fr/injuryreporting']">{{ "Menu.Link2" | translate }}</a></li>
                                @if (!onboardingCompleted) {
                                    <li><a id="logout" (click)="logout()" (keypress)="logout()" tabindex="0">{{ "Menu.Logout" | translate }}</a></li>
                                }
                            </ul>
                            <ul
                                class='ontario-application-subheader__menu custom-show-for-medium custom-hide-for-large'>
                                <li>
                                    @defer{
                                        <app-feedback></app-feedback>
                                    }
                                </li>
                            </ul>
                            <button [ngClass]="{'custom-hide-for-large': !onboardingCompleted}"
                                class='ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline'
                                id='ontario-header-menu-toggler' aria-controls='ontario-navigation'
                                aria-label='Show navigation menu' type='button'>
                                <svg class='ontario-icon' focusable='false' viewBox='0 0 24 24'
                                    preserveAspectRatio='xMidYMid meet'>
                                    <use xlink:href='#ontario-icon-menu'></use>
                                </svg>
                                <span>{{ "Menu.Menu" | translate }}</span>
                            </button>
                        </div>
                    </nav>
                </div>
            </section>
            <nav class="ontario-navigation" id="ontario-navigation">
                <button class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline"
                    id="ontario-header-nav-toggler" aria-controls="ontario-navigation"
                    aria-label="Hide navigation menu">
                    <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use xlink:href="#ontario-icon-close"></use>
                    </svg>
                    <span>{{ "Menu.Menu" | translate }}</span>
                </button>
                <div class="ontario-navigation__container">
                    <ul> 
                        <li class="custom-hide-for-large custom-hide-for-medium">
                            @defer{
                                <app-feedback></app-feedback>
                            }
                        </li>
                        <li class="custom-hide-for-large"><a [routerLink]="[translate.currentLang === 'en' ? '/injuryreporting' : 'fr/injuryreporting']">{{ "Menu.Link2" | translate }}</a></li>
                        @if (onboardingCompleted) {
                            <li><a [routerLink]="[translate.currentLang === 'en' ? '/accountsettings' : 'fr/accountsettings']">{{ "Menu.Link3" | translate }}</a></li>
                        }
                        <li><a href="javascript:{}" id="logout" (click)="logout()" (keypress)="logout()">{{ "Menu.Logout" | translate }}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
    <div class='ontario-overlay'></div>
</div>

<script src='../../../assets/ontario-design-system/scripts/ontario-a11y.js'></script>
<script src='../../../assets/ontario-design-system/scripts/ontario-header.js'></script>