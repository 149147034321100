<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Title2' | translate}}</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>              
            <h1 class="title">
                {{'NewPlanStep2.Title' | translate}}
            </h1>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
        <div class="ontario-lead-statement">{{'NewPlanStep2.Summary' | translate}}</div><br />  
        </div> 
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="Q1label">
                {{'NewPlanStep2.Q1' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                <p>{{'HardcodedGlobals.SelectAll' | translate}}</p>
            </legend>
            <div class="ontario-checkboxes ontario-checkboxes__error">
                @for(businessModule of businessModules; track businessModule; let i = $index) {
                <div>
                    <div class="checkbox-button-container">
                        <div class="ontario-checkboxes__item">
                            @if(businessModule.completed){
                                <input [checked]="checkedStatus[businessModule.id]"  (change)="updateSelectedCheckboxes(i, $event)"  class="ontario-checkboxes__input" id="checkbox-option-{{i}}" name="options" type="checkbox" value="option-1">
                                <label class="ontario-checkboxes__label" for="checkbox-option-{{i}}">
                                    {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR}}
                                </label>
                            }
                            @else {
                                <span class="fake-label">
                                    {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR}}
                                </span>
                            }
                            @if(businessModule.completed && !businessModule.dismissed){
                                <strong class="ontario-badge ontario-badge--default-heavy">{{'Tags.Completed' | translate}}</strong>
                            }
                            @if(!businessModule.completed && businessModule.completedSectionsCount > 0){
                                <strong class="ontario-badge ontario-badge ontario-badge--neutral-light">{{'Tags.Incomplete' | translate}}</strong>
                            }
                            @if(!businessModule.completed && businessModule.completedSectionsCount == 0){
                                <strong class="ontario-badge ontario-badge--default-light">{{'Tags.NotStarted' | translate}}</strong>
                            }
                        </div>
                        <button class="ontario-button ontario-button--tertiary inline-button" (click)="router.navigate(['../../../hazards/view/', businessModule.module.id], { queryParams: {return: newPlanId}, relativeTo: this.activatedRoute })"><svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                            </svg>{{ 'Buttons.Edit' | translate}}
                        </button>
                    </div>
                    @if(businessModule.completed){
                        <div class="ontario-hint-expander__container">
                            <button class="ontario-hint-expander__button" id="hint-expander-button-id-1" aria-controls="hint-expander-content-1" aria-expanded="false" data-toggle="ontario-collapse">
                                <span class="ontario-hint-expander__button-icon--close"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-chevron-up"></use>
                                    </svg></span>
                                <span class="ontario-hint-expander__button-icon--open"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-chevron-down"></use>
                                    </svg></span>
                                    {{ 'NewPlanStep2.ReviewHazardInformation' | translate}}
                            </button>
                            <div class="ontario-hint-expander__content" id="hint-expander-content-1" aria-labelledby="hint-expander-button-id-1" aria-hidden="true" data-toggle="ontario-expander-content">
                                <!-- <strong>{{'HazardTemplate.Assess' | translate}}</strong>
                                <ul>
                                    <li>{{'HazardTemplate.HowGetSick' | translate}} - <strong>{{businessModule.completed && businessModule.answers.length > 0 ? businessModule.answers[0].answerText : ''}}</strong></li>
                                    <li>{{'HazardTemplate.LikelyAffectHealth' | translate}} - <strong>{{businessModule.completed && businessModule.answers.length > 1 ? businessModule.answers[1].answerText : ''}}</strong></li>
                                    <li>{{'HazardTemplate.BadlyGetHurt' | translate}} - <strong>{{businessModule.completed && businessModule.answers.length > 2 ? businessModule.answers[2].answerText : ''}}</strong></li>
                                    <li>{{'HazardTemplate.LegalRequirements' | translate}} - <strong>{{businessModule.completed && businessModule.answers.length > 3 ? businessModule.answers[3].answerText : ''}}</strong></li>
                                </ul> -->
                                <div>
                                    @for(group of controlMeasureGroups(businessModule.id); track group) {
                                        <strong>{{groupHeader(group)}}</strong>
                                        <ul>
                                            @for(controlMeasure of groupControlMeasures(businessModule.id, group); track controlMeasure) {
                                                @defer {
                                                    <ng-container>
                                                        <li>
                                                            <strong innerHtml="{{translate.currentLang === 'en' ?  controlMeasure.answerText :  controlMeasure.answerTextFR}}"></strong>
                                                        </li>
                                                    </ng-container>    
                                                }
                                            }
                                        </ul>
                                    }
                                    <br />
                                    <ul>
                                        @for(answer of businessModule.answers; track answer) {
                                            @defer {
                                                <ng-container>
                                                    @if(answer.sectionNumber == 2){
                                                        <li >
                                                            <strong>{{ answer.answerText }}</strong>
                                                        </li>
                                                    }
                                                </ng-container>    
                                            }
                                        }
                                    </ul>
                                </div>
                                <br />
                            </div>
                        </div>
                    }
                </div>
            }  
            </div>
        </fieldset>
        <br />
            <button class="ontario-button ontario-button--secondary" (click)="back()">
                {{ 'Buttons.Back' | translate}}
            </button>
            <button (click)="saveAndContinue()"  class="ontario-button ontario-button--primary">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>
    </div>
  </div>