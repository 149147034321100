import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './services/spinner/spinner.service';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { filter, map } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { UserInfoService } from './services/user-info/user-info.service';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { BackNavigationService } from './services/back-navigation/back-navigation.service';
import { SubheaderService } from './services/subheader/subheader.service';
import { LoggingService } from './services/logging/logging.service';
import { BusinessService } from './services/business/business.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HomepageComponent, HeaderComponent, FooterComponent, BackToTopComponent, TranslateModule, SessionTimeoutComponent, BreadcrumbsComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isHomePage: boolean = false;
  constructor(private backNavigationService: BackNavigationService, private translate: TranslateService, public spinnerService: SpinnerService, private router: Router, public activatedRoute: ActivatedRoute, public authService: AuthService, public userInfoService: UserInfoService, private onboardingService: OnboardingService, private titleService: Title, public subheaderService: SubheaderService, private loggingService: LoggingService, private businessService: BusinessService) {
    this.addGAScript();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.gaTrackingId, { 'page_path': event.urlAfterRedirects });
      }
    })
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        if (this.router.url == "/" || this.router.url == "/fr") {
          this.isHomePage = true;
        } else {
          this.isHomePage = false;
        }
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    ).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`WSPB - ${title}`)
      }
      this.setUserLoggedIn()
    })
  }

  setUserLoggedIn() {
    this.userInfoService.isAuthenticated().subscribe(data => {
      let authenticated = data.value.toLowerCase() == "true"
      this.authService.setLoggedIn(authenticated)
      if (authenticated) {
        this.userInfoService.getUserInfo().subscribe(data => {
          let user = data != null && data.value != null ? JSON.parse(data.value) : null
          this.authService.setUserEmail(user.Email)
        })

        //////////////////////////
        // Forced onboarding logic
        //////////////////////////
        let currentStateOfOnboarding = "";

        this.businessService.getBusiness().subscribe(data => {
          if (data == null) {
            // nothing was completed so we need to be on /importantnotes if not already
            if (window.location.pathname.indexOf('importantnotes') == -1) {
                window.location.href = window.location.pathname.indexOf('fr') == -1 ? '/importantnotes' : '/fr/importantnotes';
            } else {
              // if already there, let user through
              return;
            }
          }

          if (data != null && data.onboardingCompleted == true && data.step1.step1Completed == true && data.step2.step2Completed == true) {
            // everything was completed so let user proceed
            return;
          }

          if (data != null && data.onboardingCompleted != true && data.step1.step1Completed == true && data.step2.step2Completed == true) {
            currentStateOfOnboarding = "/step3";
          }

          if (data != null && data.onboardingCompleted != true && data.step1.step1Completed == true && data.step2.step2Completed != true) {
            currentStateOfOnboarding = "/step2";
          }

          if (data != null && data.onboardingCompleted != true && data.step1.step1Completed != true && data.step2.step2Completed != true) {
            // no steps were completed, but data wasn't null, meaning /importantnotes was done
            currentStateOfOnboarding = "/step1";
          }

          if (currentStateOfOnboarding == "/step3" && (window.location.pathname.indexOf('step1') != -1 || window.location.pathname.indexOf('step2') != -1)) {
            // special case to allow back button during onboarding
            return;
          }

          if (currentStateOfOnboarding == "/step2" && window.location.pathname.indexOf('step1') != -1) {
            // special case to allow back button during onboarding
            return;
          }

          if (data != null && window.location.pathname.indexOf(currentStateOfOnboarding) == -1) {
            window.location.href = window.location.pathname.indexOf('fr') == -1 ? currentStateOfOnboarding : '/fr' + currentStateOfOnboarding;
          }
        })
      }
    })
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
    document.head.prepend(gtagScript);
  }
}