<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if(validationFailed){
                    <div class="ontario-alert ontario-alert--error">
                        <div class="ontario-alert__header">
                            <div class="ontario-alert__header-icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </div>
                            <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                        </div>
                        <div class="ontario-alert__body">
                            <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                            <ul>
                                @if(nameEmptyError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardNamelabel')">{{ 'CustomHazard.NameCannotBeEmpty' | translate }}</a></li>
                                }
                                @if(nameTooLongError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardNamelabel')">{{ 'CustomHazard.HazardNameHeading' | translate }} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 200} }}</a></li>
                                }
                                @if(nameInvalidError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardNamelabel')">{{ 'CustomHazard.HazardNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                                @if(descriptionTooLongError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardDescriptionlabel')">{{ 'CustomHazard.DescriptionHeading' | translate }} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 450} }}</a></li>
                                }
                                @if(descriptionInvalidError){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardDescriptionlabel')">{{ 'CustomHazard.DescriptionHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                                @if(xssCheckFailed){
                                    <li><a href="javascript:{}" (click)="scrollToElement('hazardNamelabel')">{{'HardcodedGlobals.OneOfInvalidError' | translate}}</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <h1 class="title">
                {{ 'CustomHazard.Title' | translate }}
            </h1>
            <div class="ontario-lead-statement">{{ 'CustomHazard.Summary' | translate }}</div> 
            <br/>        
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <!-- <div class="ontario-callout"> 
                <h2 class="ontario-callout__title ontario-h5">{{ 'CustomHazard.CalloutHeading' | translate }}</h2>
                <p>{{ 'CustomHazard.CalloutDescription' | translate }}</p>
            </div> -->
            <label class="ontario-label" for="hazardName" id="hazardNamelabel">
                {{'CustomHazard.HazardName' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </label>
            @if(nameEmptyError){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{ 'CustomHazard.NameCannotBeEmpty' | translate }}
                    </span>
                </div>
            }
            @if(nameTooLongError){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{ 'CustomHazard.HazardNameHeading' | translate }} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 200} }}
                    </span>
                </div>
            }
            @if(nameInvalidError){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{ 'CustomHazard.HazardNameHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}
                    </span>
                </div>
            }
            <input class="ontario-input" type="text" id="hazardName" [(ngModel)]="hazardName" [class.ontario-input__error]="nameTooLongError || nameEmptyError || nameInvalidError || xssCheckFailed">
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <label class="ontario-label" for="hazardDescription" id="hazardDescriptionlabel">
                {{'CustomHazard.Description' | translate}} <span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
            </label>
            @if(descriptionTooLongError){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{ 'CustomHazard.DescriptionHeading' | translate }} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 450} }}
                    </span>
                </div>
            }
            @if(descriptionInvalidError){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{ 'CustomHazard.DescriptionHeading' | translate }} {{'HardcodedGlobals.InvalidError' | translate}}
                    </span>
                </div>
            }
            <textarea class="ontario-input" id="hazardDescription" [(ngModel)]="hazardDescription" [class.ontario-input__error]="descriptionTooLongError || descriptionInvalidError || xssCheckFailed"></textarea> 
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <button (click)="back()" class="ontario-button ontario-button--secondary">
                {{ 'CustomHazard.Cancel' | translate}}
            </button>
            <button (click)="create()" class="ontario-button ontario-button--primary">
                {{ 'CustomHazard.Create' | translate}}
            </button>
        </div>
    </div>
</div>