<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h1 class="title">
                <span innerHtml="{{'SafetyPlans.TitleName' | translate}}"></span>
                @if(this.plan?.business?.name){
                    <span> - {{ this.plan?.business?.name }}</span>
                }   
            </h1>
            <div class="ontario-lead-statement" innerHtml="{{'SafetyPlans.ViewPlanLead' | translate}}"></div>
        </div>
        <div class="ontario-columns top-banner">
            <div class="tombstone">
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.PlanNamePlanHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.planName}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.PlanCreatedOnHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.createdOn | date}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.PlanUpdatedOnHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.lastUpdatedOn | date}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.PlanUpdatedByHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.lastUpdatedBy}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.ContactNameHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.contactName}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.DetailContactHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.contactEmail}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.ContactPhoneNumberHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.contactNumber}}</div>
                </div>
                <div class="tombstone-item">
                    <div class="item-heading">{{'SafetyPlans.SafetyPlanNotesHeading' | translate}}</div>
                    <div class="item-value">{{this.plan?.notes}}</div>
                </div>
                <div class="tombstone-buttons">
                    @if(this.isOwner === true){
                        <button (click)="initiateEdit()"
                        class="ontario-button ontario-button--secondary">{{'Buttons.Edit' | translate}}</button>
                    }
                    <button (click)="copyLink()"
                        class="ontario-button ontario-button--secondary">{{'SafetyPlans.CopyLinkButton' |
                        translate}}</button>
                    @if(showCopyAlert){
                        <span style="padding-right: 20px; margin-top: 10px;">{{'SafetyPlans.LinkCopied' | translate}}</span>
                    }
                    <button (click)="this.pdfGenerator.downloadSafetyPlanPDF(plan, currentLanguage)"
                        class="ontario-button ontario-button--secondary">{{'SafetyPlans.DownloadPDFButton' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h2 class="title" style="border-bottom: 1px solid #1A1A1A;">
            <img alt="" src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron"> {{'ImportantNotes.Heading' |
            translate}}
        </h2>
        <p class="item-top-text" innerHtml="{{'ImportantNotes.Summary' | translate}}"></p>
    </div>
</div>
<br /><br />
<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h2 class="title" style="border-bottom: 1px solid #1A1A1A;">
            <img alt="" src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron">
            {{'NewPlanStep3.Title' | translate}}
        </h2>
        <p>{{'NewPlanStep3.Introduction' | translate}}</p><br />
        <!-- <legend class="ontario-fieldset__legend" id="Q1label">
            {{'NewPlanStep3.Q1' | translate}}<span class="ontario-label__flag"></span>
        </legend> -->
        <ul>
            @for(commAndTraining of plan?.communicationTrainingOptions; track commAndTraining) {
                @defer{
                    <li>
                        @switch (commAndTraining) {
                            @case ('option-1') {
                                <ng-container>{{'NewPlanStep3.Option1' | translate}}</ng-container>
                            } @case ('option-2') {
                                <ng-container>{{'NewPlanStep3.Option2' | translate}}
                                    <ul>
                                        @for(subOption of plan?.communicationTrainingSubOptions; track subOption) {
                                            <li>
                                                @switch (subOption) {
                                                    @case ('suboption-1') {
                                                        <ng-container>{{'NewPlanStep3.Suboption1' | translate}}</ng-container>
                                                    } @case ('suboption-2') {
                                                        <ng-container>{{'NewPlanStep3.Suboption2' | translate}}</ng-container>
                                                    } @case ('suboption-3') {
                                                        <ng-container>{{'NewPlanStep3.Suboption3' | translate}}</ng-container>
                                                    } @case ('other') {
                                                        <ng-container>{{plan?.communicationTrainingOtherSubOption}}</ng-container>
                                                    }
                                                }
                                            </li>
                                        }
                                    </ul>
                                </ng-container>    
                            } @case ('option-3') {
                                <ng-container>{{'NewPlanStep3.Option3' | translate}}</ng-container>
                            } @case ('option-4') {
                                <ng-container>{{'NewPlanStep3.Option4' | translate}}</ng-container>
                            } @case ('other') {
                                <ng-container>{{plan?.communicationTrainingOtherOption}}</ng-container>
                            }
                        }
                    </li>
                }
            }
        </ul>
    </div>
</div>
<br /><br />
<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h2 class="title" style="border-bottom: 1px solid #1A1A1A;">
            <img alt="" src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron">
            {{'NewPlanStep4.Title' | translate}}
        </h2>
        <p>{{'NewPlanStep4.Summary' | translate}}</p><br />
        <!-- <legend class="ontario-fieldset__legend" id="Q1label">
            {{'NewPlanStep4.Question' | translate}}<span class="ontario-label__flag"></span>
        </legend> -->
        <p class="ontario-fieldset__legend" id="error-messaging-input-hint-and-hint-expander-hint">{{'NewPlanStep4.Question1' | translate}}</p>
        <p>{{planEvaluationInterval}}</p><br />
        <p class="ontario-fieldset__legend" id="error-messaging-input-hint-and-hint-expander-hint">{{'NewPlanStep4.Question2' | translate}}</p>
        <br />
        <ul>
            @for(evaluationOption of plan?.planEvaluationOptions; track evaluationOption) {
                @defer{
                    <li>
                        @switch (evaluationOption){
                            @case ('option-2') {
                                <ng-container>{{'NewPlanStep4.Option2' | translate}}</ng-container>
                            } @case ('option-3') {
                                <ng-container>{{'NewPlanStep4.Option3' | translate}}</ng-container>
                            } @case ('option-4') {
                                <ng-container>{{'NewPlanStep4.Option4' | translate}}</ng-container>
                            } @case ('other') {
                                <ng-container>{{plan?.planEvaluationOtherOption}}</ng-container>
                            }
                        }
                    </li>
                }
            }
        </ul>
    </div>
</div>
<br /><br />

<div class="ontario-row">
    @for(bm of this.businessModules; track bm) {
        @defer {
            <div class="ontario-columns top-banner">
                <h2 class="title" style="border-bottom: 1px solid #1A1A1A;">
                    <img alt="" src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron">
                    {{translate.currentLang == 'en' ? bm.module.name : bm.module.nameFR}}
                </h2>
                <p class="item-top-text" innerHTML="{{translate.currentLang == 'en' ? bm.module.summary : bm.module.summaryFR}}"></p>
                <h3>{{'HazardTemplate.Assess' | translate}}</h3>
                @for(section of bm.sections; track section) {
                <div>
                    @if(section.priority === 1){
                        <div>
                            <p innerHTML="{{ translate.currentLang == 'en' ? section.summary : section.summaryFR }}"></p>
                        </div>
                    }
                </div>
                }
                <br />
                
                <!-- <strong id="hazard-question1" for="hazardanswer">
                    {{'HazardTemplate.HowGetSick' | translate}}
                </strong>
                <ul>
                    <li *ngFor="let answer of filterAnswers(bm.answers, 1, 1)">{{answer.answerText}}</li>
                </ul>
                <strong id="hazard-question1" for="hazardanswer">
                    {{'HazardTemplate.LikelyAffectHealth' | translate}}
                </strong>
                <ul>
                    <li *ngFor="let answer of filterAnswers(bm.answers, 2, 1)">{{answer.answerText}}</li>
                </ul>
                <strong id="hazard-question1" for="hazardanswer">
                    {{'HazardTemplate.BadlyGetHurt' | translate}}
                </strong>
                <ul>
                    <li *ngFor="let answer of filterAnswers(bm.answers, 3, 1)">{{answer.answerText}}</li>
                </ul>
                <strong id="hazard-question1" for="hazardanswer">
                    {{'HazardTemplate.LegalRequirements' | translate}}
                </strong>
                <ul>
                    <li *ngFor="let answer of filterAnswers(bm.answers, 4, 1)">{{answer.answerText}}</li>
                </ul> -->
                <h3>{{'HazardTemplate.Control' | translate}}</h3>
                @for(section of bm.sections; track section) {
                    @defer{
                        <div>
                            @if(section.priority === 2){
                                <div>
                                    <p innerHTML="{{ translate.currentLang == 'en' ? section.summary : section.summaryFR }}"></p>
                                </div>
                            }
                        </div>
                    }
                }
                <br /> 
                @if(bm.controlMeasures!.length > 0){
                    <div>
                        <h4 id="hazard-question1" for="hazardanswer">
                            {{'HazardTemplate.SuggestionsControllingHazard' | translate}}
                        </h4>
                        <ul>
                            @for(answer of bm.controlMeasures; track answer) {
                                <li innerHtml="{{translate.currentLang == 'en' ? answer.answerText : answer.answerTextFR}}"></li>
                            }
                        </ul>
                    </div>
                }
                @if(filterAnswers(bm.answers, 2, 2).length > 0){
                    <div>
                        <strong id="hazard-question2" for="hazardanswer">
                            {{'HazardTemplate.OwnControlMeasures' | translate}}
                        </strong>
                        <ul>
                            @for(answer of filterAnswers(bm.answers, 2, 2); track answer) {
                                <li>{{answer.answerText}}</li>
                            }
                        </ul>
                    </div>
                }
            </div>
        }
    }
</div>
<br />
<br />

<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h2 class="title" style="border-bottom: 1px solid #1A1A1A;">
            <img alt="" src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron"> {{'Homepage.Disclaimer' | translate}}
        </h2>
        <p class="item-top-text" innerHtml="{{'Homepage.DisclaimerSummary' | translate}}"></p>
    </div>
</div>
<br /><br />

<div class="row">
    <div class="ontario-columns top-banner">
        @if(this.isOwner == true){
            <button (click)="initiateEdit()" class="ontario-button ontario-button--secondary">{{'Buttons.Edit' | translate}}</button>
        }
        <button (click)="copyLink()" class="ontario-button ontario-button--secondary">{{'SafetyPlans.CopyLinkButton' |
            translate}}</button>
        @if(showCopyAlert){
            <span style="padding-right: 20px; margin-top: 10px;">{{'SafetyPlans.LinkCopied' | translate}}</span>
        }
        <button (click)="this.pdfGenerator.downloadSafetyPlanPDF(plan, currentLanguage)"
            class="ontario-button ontario-button--secondary">{{'SafetyPlans.DownloadPDFButton' |
            translate}}</button>
    </div>
</div>

