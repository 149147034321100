<div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            Reset Data
          </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete all answers for a Hazard.</h4>
            <p>Provide the businessModuleId for the hazards for which all the answers should be deleted</p>
            <br />
            <fieldset class="ontario-fieldset">
                <label class="ontario-label" for="businessModuleId" id="businessModuleIdLabel">
                    BusinessModule Id<span class="ontario-label__flag">(Required)</span>
                </label>
                <input class="ontario-input" type="text" [(ngModel)]="answerBusinessModuleId"/>
            </fieldset>
            @if(answerBusinessModuleId != ''){
                <button class="ontario-button ontario-button--primary" (click)="deleteAnswers()">
                    Delete Answers
                </button>
            }
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete all hazards for the current business</h4>
            <p>It will delete all the rows from the businessModule table with its section status and section answers for the current business.</p>
            <br />
            <button class="ontario-button ontario-button--primary" (click)="deleteHazardsCurrentBusiness()">
                Delete Hazards For Current Business
            </button>
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete hazard with the provided moduleId.</h4>
            <p>Provide the moduleId for the hazard for which all the rows from the businessModule with its section status and section answers should be deleted</p>
            <br />
            <fieldset class="ontario-fieldset">
                <label class="ontario-label" for="hazardModuleId" id="hazardModuleIdLabel">
                    Module Id<span class="ontario-label__flag">(Required)</span>
                </label>
                <input class="ontario-input" type="text" [(ngModel)]="hazardModuleId"/>
            </fieldset>
            @if(hazardModuleId != ''){
                <button class="ontario-button ontario-button--primary" (click)="deleteHazard()">
                    Delete Hazard
                </button>
            }
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete all hazards for the business with the provided Business Id.</h4>
            <p>Provide the businessId for the business for which all the rows from the businessModule with its section status and section answers should be deleted</p>
            <br />
            <fieldset class="ontario-fieldset">
                <label class="ontario-label" for="hazardBusinessId" id="hazardBusinessIdLabel">
                    Business Id<span class="ontario-label__flag">(Required)</span>
                </label>
                <input class="ontario-input" type="text" [(ngModel)]="hazardBusinessId"/>
            </fieldset>
            @if(hazardBusinessId != ''){
                <button class="ontario-button ontario-button--primary" (click)="deleteHazards()">
                    Delete Hazards
                </button>
            }
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete section status for a Hazard.</h4>
            <p>Provide the businessModuleId for the hazards for which status of all the sections should be deleted</p>
            <br />
            <fieldset class="ontario-fieldset">
                <label class="ontario-label" for="businessModuleId" id="businessModuleIdLabel">
                    BusinessModule Id<span class="ontario-label__flag">(Required)</span>
                </label>
                <input class="ontario-input" type="text" [(ngModel)]="sectionBusinessModuleId"/>
            </fieldset>
            @if(sectionBusinessModuleId != ''){
                <button class="ontario-button ontario-button--primary" (click)="deleteSectionsStatus()">
                    Delete Section Status
                </button>
            }
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete all safety plans current business</h4>
            <p>It will delete all the safety plans for the current business.</p>
            <br />
            <button class="ontario-button ontario-button--primary" (click)="deleteSafetyPlansCurrentBusiness()">
                Delete Safety Plan Current Business
            </button>
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete current business</h4>
            <p>It will delete current the business.</p>
            <br />
            <button class="ontario-button ontario-button--primary" (click)="deleteCurrentBusiness()">
                Delete Current Business
            </button>
        </div>
    </div>
    <br>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <h4 class="item-top-text">Delete business with the provided businessId.</h4>
            <p>Provide the businessId for the business that should be deleted</p>
            <br />
            <fieldset class="ontario-fieldset">
                <label class="ontario-label" for="businessId" id="businessId">
                    Business Id<span class="ontario-label__flag">(Required)</span>
                </label>
                <input class="ontario-input" type="text" [(ngModel)]="businessId"/>
            </fieldset>
            @if(businessId != ''){
                <button class="ontario-button ontario-button--primary" (click)="deleteBusiness()">
                    Delete business
                </button>
            }
        </div>
    </div>
</div>