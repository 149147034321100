<div class="ontario-form-group">
    <div class="closeButton">
        <h5>{{'DismissHazard.DeleteWarning' | translate}}</h5><br />
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    <label class="ontario-label" for="dismissibleTextarea" id="text-feedback-label">
    {{'DismissHazard.DeleteWarningSummary' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
    </label>
    @if(data.feedback && data.feedback.length >= 1000 && submitButtonClicked) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'Feedback.MessageTooLongError' | translate}}
            </span>
        </div>    
    }
    @if (!xssCheckPassed) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'Feedback.MessageInvalidError' | translate}}
            </span>
        </div>    
    }
    <textarea id="dismissibleTextarea" class="ontario-input ontario-textarea" [class.ontario-input__error]="data.feedback && data.feedback.length >= 1000 && submitButtonClicked || !xssCheckPassed" name="text-feedback" [(ngModel)]="data.feedback" cdkFocusInitial></textarea>
    <button (click)="submitClick()" class="ontario-button ontario-button--primary">
        {{'Buttons.Dismiss' | translate}}
    </button>
    <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
        {{'Feedback.Cancel' | translate}}
    </button>
</div>