import { Component, ElementRef, Renderer2, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessModuleService } from 'src/app/services/business-module/business-module.service';
import { BusinessModuleModel } from 'src/app/models/models';
import { SafetyPlanService } from 'src/app/services/safetyplan/safetyplan.service';

@Component({
  selector: 'app-newplanstep2',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './newplanstep2.component.html',
  styleUrls: ['./newplanstep2.component.scss']
})
export class Newplanstep2Component {
  businessModules: BusinessModuleModel[] = [];
  newPlanId!: string;
  selectedModulesCheckboxes: { id: string }[] = [];
  checkedStatus: { [moduleId: string]: boolean } = {};
  currentGroup: string = '';

  constructor(public translate: TranslateService, public router: Router, private renderer: Renderer2, public activatedRoute: ActivatedRoute, private businessModuleService: BusinessModuleService, private safetyPlanService: SafetyPlanService) { }

  toggleExpander(expanders: any) {
    expanders.forEach(function (item: any, index: any) {
      item.addEventListener("click", function () {
        item.parentNode.classList.toggle("ontario-expander--active");
        var content = item.parentNode.querySelector(
          "[data-toggle='ontario-expander-content']"
        );
        content.classList.toggle("ontario-expander__content--opened");
        content.classList.contains("ontario-expander__content--opened")
          ? content.setAttribute("aria-hidden", "false")
          : content.setAttribute("aria-hidden", "true");
        item.parentNode.classList.contains("ontario-expander--active")
          ? item.setAttribute("aria-expanded", "true")
          : item.setAttribute("aria-expanded", "false");
      });
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.newPlanId = params['id'];
    });
    this.loadBusinessModules();
  }

  setExpandableItems() {
    var expandableItems = Array.prototype.slice.call(
      document.querySelectorAll("[data-toggle='ontario-collapse']")
    );
    this.toggleExpander(expandableItems);
  }

  loadBusinessModules() {
    if (this.newPlanId) {
      this.safetyPlanService.getPlanDetails(this.newPlanId).subscribe((details: any) => {
        if (!details) 
          this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
      });
    }
    this.businessModuleService.getBusinessModules('', 'doing').subscribe(bm => {
      const filteredModules = bm.filter(module => module.module.priority <= 12 || module.module.priority == 500);
      this.businessModules = filteredModules;
      this.businessModules = this.businessModules.filter(x => !x.dismissed);
      this.businessModules = this.businessModules.sort((a: any, b: any) => {
        return b.completedSectionsCount - a.completedSectionsCount;
      });
      setTimeout(() => { this.setExpandableItems(); }, 1000);
      this.businessModules.forEach(businessModule => {
        if (businessModule.completed) {
          this.safetyPlanService.checkSafetyPlanBusinessModuleExists(this.newPlanId, businessModule.id).subscribe((details: any) => {
            if (details == true) {
              this.checkedStatus[businessModule.id] = true;
              this.selectedModulesCheckboxes.push({ id: businessModule.id });
            }
          });
        }
      });
    });
  }

  back() {
    this.router.navigate(['../../newplanstep1', this.newPlanId], { relativeTo: this.activatedRoute })
  }

  updateSelectedCheckboxes(i: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    const moduleId = this.businessModules[i].id;
    if (isChecked) {
      this.selectedModulesCheckboxes.push({ id: moduleId });
    } else {
      const index = this.selectedModulesCheckboxes.findIndex(module => module.id === moduleId);
      if (index !== -1) {
        this.selectedModulesCheckboxes.splice(index, 1);
      }
    }
  }

  saveAndContinue() {
    const businessModuleIds = this.selectedModulesCheckboxes.map(businessModule => businessModule.id);
    this.safetyPlanService.updateStep2Plan(this.newPlanId).subscribe((newPlanId: string) => {
      this.safetyPlanService.addOrUpdateSafetyPlanBusinessModules({
        safetyPlanId: newPlanId,
        businessModuleIds: businessModuleIds
      }).subscribe((newPlanId: string) => {
        this.router.navigate(['../../newplanstep3', newPlanId], { relativeTo: this.activatedRoute });
      });
    });
  }

  controlMeasureGroups(businessModuleId: string): string[] {
    const bm = this.businessModules.filter(x => x.id === businessModuleId)[0];
    return  [... new Set(bm.controlMeasures?.map<string>(cm => cm.group))];
  }

  groupControlMeasures(businessModuleId: string, group: string): any[] {
    const bm = this.businessModules.filter(x => x.id === businessModuleId)[0];
    return bm.controlMeasures?.filter(x => x.group === group) || [];
  } 

  

  groupHeader(group: string) {
    switch(group){
      case 'EliminationAndSubstitution': return this.translate.instant('HazardTemplate.EliminationAndSubstitution');
      case 'EngineeringControls': return this.translate.instant('HazardTemplate.EngineeringControls');
      case 'AdministrativeControls': return this.translate.instant('HazardTemplate.AdministrativeControls');
      case 'HygieneFacilitiesAndPractices': return this.translate.instant('HazardTemplate.HygieneFacilitiesAndPractices');
      case 'PersonalProtectiveEquipment': return this.translate.instant('HazardTemplate.PersonalProtectiveEquipment');
    }
  }
}