export class ControlMeasureModel {
    constructor(
        public id: string,
        public moduleId: string,
        public answerText: string,
        public answerTextFR: string,
        public priority: number,
        public state: string,
        public group: string
    ) {
    }
}

export class BusinessModuleControlMeasureModel {
    constructor(
        public id: string,
        public businessModuleID: string,
        public controlMeasureID: string,
        public state:string
    ) {
    }
}

export class SelectedBusinessModuleControlMeasureModel {
    constructor(
        public businessModuleID: string,
        public controlMeasureID: string,
        public state:string
    ) {
    }
}