<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR}}
          </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
        <p class="ontario-lead-statement">{{'HazardSummary.Description' | translate}}</p><br /> 
        <div>
            <!-- <div class="heading-container">
                <h2>{{'HazardTemplate.Assess' | translate}}</h2><br /> 
                <button (click)="backToSection(1)" aria-label="for Avoid Edit" class="ontario-button ontario-button--tertiary inline-button"><svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                </svg>{{ 'Buttons.Edit' | translate}}</button>
            </div>
            <strong id="hazard-question1" for="hazardanswer">
                {{'HazardTemplate.HowGetSick' | translate}}
            </strong>
            <ul>
                <li *ngFor="let answer of filterAnswers(hazardAnswersS1, 1)">{{answer.answerText}}</li>
            </ul>
            <strong id="hazard-question1" for="hazardanswer">
                {{'HazardTemplate.LikelyAffectHealth' | translate}}
            </strong>
            <ul>
                <li *ngFor="let answer of filterAnswers(hazardAnswersS1, 2)">{{answer.answerText}}</li>
            </ul>
            <strong id="hazard-question1" for="hazardanswer">
                {{'HazardTemplate.BadlyGetHurt' | translate}}
            </strong>
            <ul>
                <li *ngFor="let answer of filterAnswers(hazardAnswersS1, 3)">{{answer.answerText}}</li>
            </ul>
            <strong id="hazard-question1" for="hazardanswer">
                {{'HazardTemplate.LegalRequirements' | translate}}
            </strong>
            <ul>
                <li *ngFor="let answer of filterAnswers(hazardAnswersS1, 4)">{{answer.answerText}}</li>
            </ul> -->
            <div class="heading-container">
                <h2>{{'HazardTemplate.Control' | translate}}</h2><br /> 
                <button (click)="backToSection(2)" aria-label="for Elimination Edit" class="ontario-button ontario-button--tertiary inline-button"><svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                </svg>{{ 'Buttons.Edit' | translate}}</button>
            </div>
            @if (hazardAnswersS2A1.length > 0) {
                @if (hazardAnswersS2A1[0].length > 0) {
                    <h3 id="hazard-question1" for="hazardanswer">
                        {{'Step2.ActivelyDoing' | translate}}
                    </h3>
                    <ul>
                        @for(answer of hazardAnswersS2A1[0]; track answer;) {
                            <li innerHtml="{{translate.currentLang == 'en' ? answer.answerText : answer.answerTextFR}}"></li>
                        }
                    </ul>
                }
                @if (hazardAnswersS2A1[1].length > 0) {
                    <h3 id="hazard-question1-2" for="hazardanswer">
                        {{'Step2.WillConsider' | translate}}
                    </h3>
                    <ul>
                        @for(answer of hazardAnswersS2A1[1]; track answer;) {
                            <li innerHtml="{{translate.currentLang == 'en' ? answer.answerText : answer.answerTextFR}}"></li>
                        }
                    </ul>
                }
                @if (hazardAnswersS2A1[2].length > 0) {
                    <h3 id="hazard-question1-3" for="hazardanswer">
                        {{'Step2.NotDoing' | translate}}
                    </h3>
                    <ul>
                        @for(answer of hazardAnswersS2A1[2]; track answer;) {
                            <li innerHtml="{{translate.currentLang == 'en' ? answer.answerText : answer.answerTextFR}}"></li>
                        }
                    </ul>
                }
            }
            @if (hazardAnswersS2A2.length > 0) {
                <div>
                    <h3 id="hazard-question2" for="hazardanswer">
                        {{'HazardTemplate.OwnControlMeasures' | translate}}
                    </h3>
                    <ul>
                        @for(answer of hazardAnswersS2A2; track answer) {
                            <li>{{answer.answerText}}</li>
                        }
                    </ul>
                </div>   
            }
        </div>
        <br />
        <h2>{{'HazardTemplate.Evaluate' | translate}}</h2>
        <p innerHtml="{{'HazardSummary.Evaluate' | translate}}"></p><br />  
        <button class="ontario-button ontario-button--primary" (click)="saveAndComplete()">
            {{ 'Buttons.SaveAndComplete' | translate}}
        </button>
        </div>
        
</div>
   